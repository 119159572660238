import React, { Fragment, useContext } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'

import { BlogPostPageQuery } from '../../../../types/graphql-types'
import { text_space_max_width, common_space } from '../../../style/common_value'
import { blog_post_bottom_aside_ref_context } from '../Layout'

import BlogPostMetadata from '../../metadata/BlogPostMetadata'
import BlogPostHelmet from './BlogPostHelmet'
import BlogPostHeader from './BlogPostHeader'
import BlogPostInfo from './BlogPostInfo'
import BlogPostMainText from './BlogPostMainText'
import BlogPostBottomShareButtons from './BlogPostBottomShareButtons'
import BlogPostNextPrev from './BlogPostNextPrev'

const main_conteiner_style = css({
  margin: '0 1rem',
  paddingBottom: common_space.m,
  maxWidth: text_space_max_width,
  '@media screen and (min-width:816px)': {
    margin: '0 auto',
    paddingBottom: common_space.l,
  },
})

type props_type = {
  data?: BlogPostPageQuery
}

export const BlogPost: React.FC<props_type> = ({ data }) => {
  const blog_post = data.contentfulBlogPost
  const meta_data = data.contentfulSiteMetaData

  const aside_ref = useContext(blog_post_bottom_aside_ref_context)

  return (
    <>
      <BlogPostHelmet />
      <BlogPostMetadata blog_post={blog_post} meta_data={meta_data} />
      <article>
        <BlogPostHeader blog_post={blog_post} />
        <div css={main_conteiner_style}>
          <BlogPostInfo blog_post={blog_post} />
          <BlogPostMainText blog_post={blog_post} />
        </div>
      </article>
      <aside ref={aside_ref}>
        <BlogPostBottomShareButtons blog_post={blog_post} />
        <BlogPostNextPrev blog_post={blog_post} />
      </aside>
    </>
  )
}

export default BlogPost

export const result = graphql`
  query BlogPostPage($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      author {
        author_name
        url
      }
      published_date
      modified_date
      category {
        name
        slug
        master_category {
          name
          slug
        }
        header_image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        header_image_center_point
        thumbnail {
          title
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        ogp_images {
          title
          description
          file {
            fileName
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      thumbnail {
        title
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      tag {
        name
        id
        slug
      }
      header_image {
        title
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      ogp_images {
        title
        description
        file {
          fileName
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      header_image_center_point
      description {
        childMarkdownRemark {
          html
          excerpt(format: PLAIN, truncate: true)
        }
      }
      table_of_contents_disable
      main_text {
        childMarkdownRemark {
          html
          excerpt(format: PLAIN, truncate: true)
        }
      }
    }
    contentfulSiteMetaData {
      site_title
      site_url
      site_description
      author_data {
        author_name
        url
      }
      ogp_images {
        title
        description
        file {
          fileName
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      logo_image {
        file {
          url
        }
      }
    }
  }
`
