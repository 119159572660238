import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import { BlogPostPageQuery } from '../../../../types/graphql-types'

import TagCard from '../../atoms/TagCard'
import { media_mobile } from '../../../style/common_value'
import { ShareButtons } from '../../molecules/ShareButtons'
import SvgCategory from '../../svg_components/Category'

const info_container_style = css({
  padding: '3rem 0 0',
  margin: '0 0 3rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  '& > div, & > ul': {
    marginBottom: '1.5rem',
  },
  '& > div:last-of-type': {
    marginBottom: '0',
  },
  [media_mobile.tab]: {
    padding: '6rem 0 0',
    margin: '0 0 6rem',
    '& > div, & > ul': {
      marginBottom: '3rem',
    },
    '& > div:last-of-type': {
      marginBottom: '0',
    },
  },
})

const category_style = css({
  margin: '0 auto',
  // marginTop: '1rem',
  display: 'inline-block',
  fontSize: '1rem',
  [media_mobile.tab]: {
    // marginTop: '2rem',
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
  },
  a: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      marginRight: '.5rem',
    },
  },
})

const tag_container_style = css({
  // margin: '0 -0.6rem',
  // marginBottom: common_space.s,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  [media_mobile.tab]: {
    // marginBottom: '3rem',
  },
})

const share_buttons_wrapper_style = css({
  // margin: `${common_space.s} auto 0`,
  [media_mobile.tab]: {
    // marginTop: '3rem',
  },
})

type props_type = {
  blog_post: BlogPostPageQuery['contentfulBlogPost']
}

export const BlogPostInfo: React.FC<props_type> = ({ blog_post }) => {
  const union_slug = `/${blog_post.slug}`
  return (
    <div className="info_container" css={info_container_style}>
      <div css={category_style}>
        <Link
          to={`/${blog_post.category.master_category.slug}/${blog_post.category.slug}`}
        >
          <SvgCategory />
          <span>{blog_post.category.name}</span>
        </Link>
      </div>
      {blog_post.tag ? (
        <ul className="tag_container" css={tag_container_style}>
          {blog_post.tag.map((tag) => {
            return (
              <TagCard
                tag_name={tag.name}
                link_tag_slug={tag.slug}
                font_size=".8rem"
                key={tag.id}
              />
            )
          })}
        </ul>
      ) : null}
      <div css={share_buttons_wrapper_style}>
        <ShareButtons union_slug={union_slug} blog_post={blog_post} />
      </div>
    </div>
  )
}

export default BlogPostInfo
