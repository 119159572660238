import React from 'react'
import { css } from '@emotion/react'

import { BlogPostPageQuery } from '../../../../types/graphql-types'
import { common_color, common_space } from '../../../style/common_value'

import { ShareButtons } from '../../molecules/ShareButtons'

const share_buttons_wrapper_style = css({
  display: 'flex',
  justifyContent: 'center',
  padding: `${common_space.s} 0 .5rem`,
  backgroundColor: common_color.gray,
})

type props_type = {
  blog_post: BlogPostPageQuery['contentfulBlogPost']
}

export const BlogPostBottomShareButtons: React.FC<props_type> = ({
  blog_post,
}) => {
  const union_slug = `/${blog_post.slug}`
  return (
    <div css={share_buttons_wrapper_style}>
      <ShareButtons
        union_slug={union_slug}
        blog_post={blog_post}
        base_color="white"
        icon_color={common_color.gray}
        button_margin="2rem"
      />
    </div>
  )
}

export default BlogPostBottomShareButtons
