/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

type window_iframely_type = Window & {
  iframely?: {
    load?: any
  }
}

export const BlogPostIframely: React.FC = () => {
  useEffect(() => {
    const window_iframely: window_iframely_type = window
    if (window_iframely.iframely && 'load' in window_iframely.iframely) {
      window_iframely.iframely.load()
    }
  }, [])
  return (
    <Helmet>
      <script async src="https://cdn.iframe.ly/embed.js" />
    </Helmet>
  )
}

export default BlogPostIframely
