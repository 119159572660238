import React from 'react'
import { motion } from 'framer-motion'
import { Link as ScrollLink } from 'react-scroll'
import cheerio from 'cheerio'

import { BlogPostPageQuery } from '../../../../../types/graphql-types'
import {
  table_of_contents_main_container_style,
  table_of_contents_main_item_style,
} from '../blog_post_css/blog_post_table_of_contents'

// NOTE: cheerio の types から copy した型定義
interface TagElement {
  tagName: string
  type: 'tag' | 'script' | 'style'
  name: string
  attribs: { [attr: string]: string }
  'x-attribsNamespace': { [attr: string]: string }
  'x-prefixNamespace': { [attr: string]: string }
  children: Element[]
  childNodes: Element[] | null
  lastChild: Element | null
  firstChild: Element | null
  next: Element | null
  nextSibling: Element
  prev: Element | null
  previousSibling: Element
  parent: Element
  parentNode: Element
  nodeValue: string
  data?: string | undefined
  startIndex?: number | undefined
  endIndex?: number | undefined
}

const create_toc = (html: string) => {
  const c_obj = cheerio.load(html)
  const match_obj = c_obj('h2, h3, h4, h5').children('a')
  const result = []
  for (const i of [...Array(match_obj.length).keys()]) {
    // HACK: TagElement しか入らないと仮定しているので、外の型が入ってきた場合 Error となる可能性がある
    const temp_parent = match_obj[i].parent as unknown as TagElement

    result.push({
      tag_name: temp_parent.name.toLowerCase(),
      id: temp_parent.attribs.id,
      title: match_obj[i].next.data,
    })
  }
  return result
}

const main_variants = {
  close: {
    width: '0',
    height: '0',
    borderWidth: 0,
    transition: {
      width: { duration: 0.3, delay: 0.3 },
      height: { duration: 0.3 },
      borderWidth: { duration: 0.1, delay: 0.3 },
      ease: 'easeInOut',
    },
  },
  open: {
    width: '100%',
    height: '100%',
    borderWidth: 3,
    transition: {
      width: { duration: 0.3 },
      height: { duration: 0.3, delay: 0.3 },
      borderWidth: { duration: 0.1, delay: 0.3 },
      ease: 'easeInOut',
    },
  },
}

type props_type = {
  html: BlogPostPageQuery['contentfulBlogPost']['main_text']['childMarkdownRemark']['html']
}

export const TableOfContentsMain: React.FC<props_type> = ({ html }) => {
  return (
    <motion.div
      css={table_of_contents_main_container_style}
      variants={main_variants}
    >
      <ul>
        {create_toc(html).map((elem) => (
          <li key={elem.id} css={table_of_contents_main_item_style}>
            <ScrollLink to={elem.id} smooth offset={-63}>
              <div className={elem.tag_name}>{elem.title}</div>
            </ScrollLink>
          </li>
        ))}
      </ul>
    </motion.div>
  )
}

export default TableOfContentsMain
