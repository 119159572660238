import { css } from '@emotion/react'

import {
  common_color,
  common_space,
  media_mobile,
  font_family,
} from '../../../../style/common_value'

export const adjust_prismjs_style = css({
  // コードタイトル
  '.gatsby-code-title': {
    display: 'table',
    width: 'fit-content',
    position: 'relative',
    zIndex: 1,
    marginBottom: '-1.2rem',
    marginLeft: '1rem',
    marginTop: `calc(${common_space.s} - 1rem)`,
    [media_mobile.tab]: {
      marginTop: `calc(${common_space.m} - 1rem)`,
    },
    padding: '0.1rem 1rem',
    // fontFamily:
    //   '"Fira Code", Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono","Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',
    fontFamily: font_family.fira_code,
    '@supports (font-variation-settings: normal)': {
      fontFamily: font_family.fira_code_vf,
    },
    fontSize: '1rem',
    lineHeight: '2rem',
    backgroundColor: common_color.gray_dark, // '#686868',
    color: 'white',
    borderRadius: '0.3rem',
  },
  '.gatsby-code-title + .gatsby-highlight pre[class*="language-"]': {
    padding: '2.8rem 2rem 2rem',
  },
  // gatsby-remark-prismjs設定
  '.gatsby-highlight': {
    borderRadius: '0.3em',
    letterSpacing: '0',
    margin: `${common_space.s} 0`,
    overflow: 'auto',
    [media_mobile.tab]: {
      margin: `${common_space.m} 0`,
    },
    '& + .gatsby-highlight': {
      marginTop: '0',
    },
    '& + .gatsby-code-title': {
      marginTop: '0',
    },
  },
  '.gatsby-code-title + .gatsby-highlight': {
    marginTop: '0',
  },
  // テキスト内コードブロック
  'code.language-text': {
    letterSpacing: '0rem',
    // fontFamily: font_family.kukumincho,
    // fontDisplay: 'swap',
    // fontSize: '16px',
    // lineHeight: '1',
    // letterSpacing: '0.12rem',
    // fontFamily: '"Fira Code", monospace',
    // '@supports (font-variation-settings: normal)': {
    //   code: { fontFamily: '"Fira Code VF", monospace' },
    // },
    // fontFeatureSettings: '"calt"',
    margin: '0 .2rem',
    padding: '.3rem .4rem .2rem',
    // letterSpacing: '0',
    color: common_color.text, // 'white',
    textShadow: 'none',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all', // SPで長い単語でレイアウト崩れが起きないように改行
    backgroundColor: common_color.orcher_verylight,
    borderRadius: '0.2rem',
    // background: effect.underline(common_color.yellow_light),
  },
  // 行のハイライト
  '.gatsby-highlight pre[class*="language-"]': {
    margin: '0',
    overflow: 'initial',
    float: 'left',
    minWidth: '100%',
  },
  '.gatsby-highlight-code-line': {
    backgroundColor: '#333',
    padding: '4px',
    margin: '2px 0',
    border: `1px solid ${common_color.yellow}`,

    display: 'block',
    '& + .gatsby-highlight-code-line': {
      borderTop: 'none',
      marginTop: '-3px',
      paddingTop: '0',
    },
  },
  // Shell出力を再現
  // '.command-line-prompt': {
  //   borderRight: '1px solid #999',
  //   display: 'block',
  //   float: 'left',
  //   fontSize: '100%',
  //   letterSpacing: '-1px',
  //   marginRight: '1em',
  //   'pointer-events': 'none',

  //   'user-select': 'none',
  // },

  // '.command-line-prompt > span::before': {
  //   color: '#999',
  //   content: '" "',
  //   display: 'block',
  //   paddingRight: '0.8em',
  // },

  // '.command-line-prompt > span[data-user]::before': {
  //   content: '"[" attr(data-user) "@" attr(data-host) "] $"',
  // },

  // '.command-line-prompt > span[data-user="root"]::before': {
  //   content: '"[" attr(data-user) "@" attr(data-host) "] #"',
  // },

  // '.command-line-prompt > span[data-prompt]::before': {
  //   content: 'attr(data-prompt)',
  // },

  // 行番号表示
  // 'pre[class*="language-"].line-numbers': {
  //   position: 'relative',
  //   paddingLeft: '3.8em',
  //   counterReset: 'linenumber',
  // },

  // 'pre[class*="language-"].line-numbers > code': {
  //   position: 'relative',
  //   whiteSpace: 'inherit',
  // },

  // '.line-numbers .line-numbers-rows': {
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   top: '0',
  //   fontSize: '100%',
  //   left: '-3.8em',
  //   width: '3em', // orks for line-numbers below 1000 lines
  //   letterSpacing: '-1px',
  //   borderRight: '1px solid #999',

  //   userSelect: 'none',
  // },

  // '.line-numbers-rows > span': {
  //   display: 'block',
  //   counterIncrement: 'linenumber',
  // },

  // '.line-numbers-rows > span::before': {
  //   content: 'counter(linenumber)',
  //   color: '#999',
  //   display: 'block',
  //   paddingRight: '0.8em',
  //   textAlign: 'right',
  // },
})

export default adjust_prismjs_style
