import { css } from '@emotion/react'

import { common_color, media_mobile } from '../../../../style/common_value'
import { svg_indicator_right } from '../../../../style/svg_in_css'

export const table_of_contents_style = css({
  position: 'relative',
  margin: '0 auto',
  marginBottom: '3rem',
  width: 'fit-content',
  fontSize: '1rem',
  lineHeight: '1.5',
  [media_mobile.tab]: {
    fontSize: '1.1rem',
    lineHeight: '1.5',
    marginBottom: '7rem',
    maxWidth: '70%',
  },
})

export const table_of_contents_caption_wapper_style = css({
  cursor: 'pointer',
  margin: '0 auto',
  fontSize: '1.1em',
  border: `1px solid ${common_color.gray}`,
  borderStyle: 'solid',
  textAlign: 'center',
})

export const table_of_contents_caption_container_style = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.2rem 1rem',
})

export const svg_laytou_list_style = css({
  cursor: 'pointer',
  display: 'inline',
  width: '1rem',
  height: '1rem',
  marginRight: '.5rem',
})

export const table_of_contents_main_container_style = css({
  position: 'relative',
  overflow: 'hidden',
  margin: '0 auto 0',
  borderTop: 'none',
  borderBottom: `solid 3px ${common_color.gray}` /* 線の種類 太さ 色 */,
  borderLeft: `solid 3px ${common_color.gray}` /* 線の種類 太さ 色 */,
  borderRight: `solid 3px ${common_color.gray}` /* 線の種類 太さ 色 */,
  borderRadius: '0 0 4px 4px',
  lineHeight: '2',
  ul: {
    margin: '1rem 2rem 1rem 3rem',
  },
})

export const table_of_contents_main_item_style = css({
  padding: '.5rem 0',
  div: {
    position: 'relative',
    paddint: ' 0',
    '&::before': {
      position: 'absolute',
      top: '.3em',
      left: '-1.8em',
      width: '1.2em',
      height: '1.2em',
      content: '""',
      display: 'inline-block',
      backgroundImage: svg_indicator_right(),
    },
  },
  '.h3': {
    marginLeft: '1.5rem',
    [media_mobile.tab]: {
      marginLeft: '2rem',
    },
  },
  '.h4': {
    marginLeft: '3rem',
    [media_mobile.tab]: {
      marginLeft: '4rem',
    },
  },
  '.h5': {
    marginLeft: '4.5rem',
    [media_mobile.tab]: {
      marginLeft: '6rem',
    },
  },
  a: {
    color: common_color.link,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})
