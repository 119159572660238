import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  HatenaShareButton,
  HatenaIcon,
  LineShareButton,
  LineIcon,
  PocketShareButton,
  PocketIcon,
} from 'react-share'

import { MoleculesShareButtonsQuery } from '../../../types/graphql-types'
import { common_color, media_mobile } from '../../style/common_value'

const size = '2.5rem'

interface blog_post_type {
  title?: string
  category?: {
    name?: string
  }
}

type props_type = {
  blog_post?: blog_post_type
  title?: string
  union_slug?: string
  icon_size?: string
  base_color?: string
  icon_color?: string
  button_margin?: string
}

export const ShareButtons: React.FC<props_type> = ({
  blog_post,
  title,
  union_slug,
  icon_size,
  base_color,
  icon_color,
  button_margin,
}) => {
  const query: MoleculesShareButtonsQuery = useStaticQuery(graphql`
    query MoleculesShareButtons {
      contentfulSiteMetaData {
        site_title
        site_description
        site_url
        author_data {
          twitter_account
        }
      }
    }
  `)

  const meta_data = query.contentfulSiteMetaData

  let share_title: string
  if (blog_post) {
    share_title = `【${blog_post.category.name}】${blog_post.title} | ${meta_data.site_title}`
  } else if (title) {
    share_title = `${title} | ${meta_data.site_title}`
  } else {
    share_title = `${meta_data.site_title}`
  }

  const share_url = union_slug
    ? `${meta_data.site_url}${union_slug}`
    : `${meta_data.site_url}`

  const share_icon_size = icon_size ?? size

  const icon_style = {
    fill: base_color ?? common_color.gray,
  }

  const icon_fill_color = icon_color ?? 'white'

  const icon_radius = 10

  const share_buttons_container_style = css({
    display: 'inline-block',
    button: {
      margin: '0 .5rem',
      transition: '.1s',
      [media_mobile.tab]: {
        marginRight: button_margin ?? '1rem',
      },
      ':hover': {
        transform: 'scale(1.2, 1.2)',
        filter: 'drop-shadow(2px 2px 1px rgba(0,0,0,0.4))',
      },
    },
  })
  return (
    <div css={share_buttons_container_style}>
      <TwitterShareButton
        url={share_url}
        title={share_title}
        related={[meta_data.author_data.twitter_account]}
      >
        <TwitterIcon
          size={share_icon_size}
          bgStyle={icon_style}
          borderRadius={icon_radius}
          iconFillColor={icon_fill_color}
        />
      </TwitterShareButton>
      <FacebookShareButton url={share_url} title={share_title}>
        <FacebookIcon
          size={share_icon_size}
          bgStyle={icon_style}
          borderRadius={icon_radius}
          iconFillColor={icon_fill_color}
        />
      </FacebookShareButton>
      <HatenaShareButton url={share_url} title={share_title}>
        <HatenaIcon
          size={share_icon_size}
          bgStyle={icon_style}
          borderRadius={icon_radius}
          iconFillColor={icon_fill_color}
        />
      </HatenaShareButton>
      <PocketShareButton url={share_url} title={share_title}>
        <PocketIcon
          size={share_icon_size}
          bgStyle={icon_style}
          borderRadius={icon_radius}
          iconFillColor={icon_fill_color}
        />
      </PocketShareButton>
      <LineShareButton url={share_url} title={share_title}>
        <LineIcon
          size={share_icon_size}
          bgStyle={icon_style}
          borderRadius={icon_radius}
          iconFillColor={icon_fill_color}
        />
      </LineShareButton>
    </div>
  )
}

export default ShareButtons
