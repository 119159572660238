import { css } from '@emotion/react'
import { font_family } from '../../../../style/common_value'

export const prism_okaidia_style = css({
  'code[class*="language-"], pre[class*="language-"]': {
    color: '#f8f8f2',
    background: 'none',
    textShadow: '0 1px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    tabSize: '4',
    hyphens: 'none',
    // FiraCode追加
    fontFamily: font_family.fira_code,
    '@supports (font-variation-settings: normal)': {
      fontFamily: font_family.fira_code_vf,
    },
    fontFeatureSettings: '"calt"',
    // fontVariantLigatures: 'contextual',
    fontSize: '1rem',
  },

  /* Code blocks */
  'pre[class*="language-"]': {
    padding: '2rem',
    margin: '0.5em 0',
    overflow: 'auto',
    borderRadius: '0.3em',
  },

  ':not(pre) > code[class*="language-"], pre[class*="language-"]': {
    background: '#272822',
  },

  /* Inline code */
  ':not(pre) > code[class*="language-"]': {
    padding: '0.1em',
    borderRadius: '0.3em',
    whiteSpace: 'normal',
  },

  '.token.comment, .token.prolog, .token.doctype, .token.cdata': {
    color: '#8292a2',
  },

  '.token.punctuation': {
    color: '#f8f8f2',
  },

  '.token.namespace': {
    opacity: '0.7',
  },

  '.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted':
    {
      color: '#f92672',
    },

  '.token.boolean, .token.number': {
    color: '#ae81ff',
  },

  '.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted':
    {
      color: '#a6e22e',
    },

  '.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string, .token.variable':
    {
      color: '#f8f8f2',
    },

  '.token.atrule, .token.attr-value, .token.function, .token.class-name': {
    color: '#e6db74',
  },

  '.token.keyword': {
    color: '#66d9ef',
  },

  '.token.regex, .token.important': {
    color: '#fd971f',
  },

  '.token.important, .token.bold': {
    fontWeight: 'bold',
  },
  '.token.italic': {
    fontStyle: 'italic',
  },

  '.token.entity': {
    cursor: 'help',
  },
})

export default prism_okaidia_style
