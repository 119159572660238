/* eslint-disable react/no-danger */
import React from 'react'
import { css } from '@emotion/react'
import mikan from 'mikanjs'

import { BlogPostPageQuery } from '../../../../types/graphql-types'
import {
  media_mobile,
  media_tablet_landscape,
  text_space_max_width,
  too_long_title,
  common_space,
} from '../../../style/common_value'
import { date_format_dot_separate } from '../../../utils'

import HeaderImage from '../../atoms/HeaderImage'
import SvgUpload from '../../svg_components/Upload'
import SvgRefresh from '../../svg_components/Refresh'

const header_image_style = css({
  height: '60vh',
  [media_mobile.sp]: {
    height: '40vh',
  },
  // 幅1024px以下の横長（タブレット横）
  [media_tablet_landscape]: {
    height: '80vh',
  },
})

const title_container_style = css({
  width: '100%',
  height: 'auto',
  textAlign: 'center',
  padding: '2rem .5rem 0',
  [media_mobile.sp]: {
    padding: '0',
  },
})

const title_style = css({
  margin: '0 auto',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  maxWidth: text_space_max_width,
  [media_mobile.sp]: {
    fontSize: '2.1rem',
    lineHeight: '2.5rem',
    maxWidth: '600px',
  },
  [media_mobile.tab]: {
    fontSize: '2.7rem',
    lineHeight: '3.3rem',
    maxWidth: text_space_max_width,
  },
  // 幅1024px以下の横長（タブレット横）
  [media_tablet_landscape]: {
    fontSize: '1.5rem',
    lineHeight: '1.9rem',
  },
})

const date_wrapper_style = css({
  margin: `${common_space.s} auto -1rem`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [media_mobile.tab]: {
    flexDirection: 'row',
    marginTop: '3rem',
  },
})

const date_container_style = css({
  marginBottom: '.4rem',
  span: {
    marginRight: '.6rem',
  },
  '&:last-of-type': {
    marginRight: '0',
    marginBottom: '-1.4rem',
  },
  [media_mobile.tab]: {
    marginBottom: '0',
    marginRight: '.8rem',
    '&:last-of-type': {
      marginRight: '0',
      marginBottom: '0',
    },
  },
})

const date_icon_style = css({
  position: 'relative',
  top: '1.5px',
  fill: 'white',
})

type props_type = {
  blog_post: BlogPostPageQuery['contentfulBlogPost']
}

export const BlogPostHeader: React.FC<props_type> = ({ blog_post }) => {
  const { header_image, header_image_center_point } = blog_post.header_image
    ? {
        header_image: blog_post.header_image,
        header_image_center_point: blog_post.header_image_center_point,
      }
    : {
        header_image: blog_post.category.header_image,
        header_image_center_point: blog_post.category.header_image_center_point,
      }
  const long_title_size =
    blog_post.title.length > too_long_title
      ? {
          fontSize: '1.2rem',
          lineHeight: '1.6rem',
        }
      : {}
  return (
    <HeaderImage
      image_set={header_image}
      object_position={header_image_center_point}
      css_overwrite={header_image_style}
    >
      <div css={title_container_style}>
        <h1 css={[title_style, long_title_size]}>
          <div dangerouslySetInnerHTML={{ __html: mikan(blog_post.title) }} />
        </h1>
        <div css={date_wrapper_style}>
          <div css={date_container_style}>
            <span>
              <SvgUpload css={date_icon_style} />
            </span>
            {date_format_dot_separate(blog_post.published_date)}
          </div>
          {blog_post.modified_date &&
            blog_post.modified_date !== blog_post.published_date && (
              <div css={date_container_style}>
                <span>
                  <SvgRefresh css={date_icon_style} />
                </span>
                {date_format_dot_separate(blog_post.modified_date)}
              </div>
            )}
        </div>
      </div>
    </HeaderImage>
  )
}

export default BlogPostHeader
