import { css } from '@emotion/react'

import {
  common_color,
  common_space,
  media_mobile,
  effect,
} from '../../../../style/common_value'
import {
  svg_ticksign,
  svg_quote_round,
  svg_kilroy,
} from '../../../../style/svg_in_css'

import { prism_okaidia_style } from './prism-okaidia'
import { adjust_prismjs_style } from './adjust_prismjs_css'
import { emoji_style } from '../../../../style/emoji_style'

const import_number_font_style = css({
  '@font-face': {
    fontFamily: 'EnglishGothic',
    fontDisplay: 'swap',
    src: 'url("/web_fonts/EnglishGothic/EnglishGothic.woff2") format("woff2")',
  },
})

export const main_text_style = css(
  import_number_font_style,
  prism_okaidia_style,
  adjust_prismjs_style,
  emoji_style,
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '1rem',
    lineHeight: '1.8',
    marginBottom: '3rem',
    overflowWrap: 'break-word',
    [media_mobile.tab]: {
      marginBottom: '6rem',
      fontSize: '1.2rem',
      lineHeight: '2',
    },
    // gatsby-remark-line-breaks代替の改行用。表示崩れがないか注意
    '> p': {
      whiteSpace: 'pre-wrap',
      '*': {
        whiteSpace: 'normal',
      },
    },
    'p + p': {
      marginTop: common_space.s,
      [media_mobile.tab]: {
        marginTop: '3rem',
      },
    },
    a: {
      color: common_color.link,
      textDecoration: 'underline',
      lineHeight: '1em',
    },
    'h2, h3, h4': {
      display: 'inline-box',
      fontWeight: 'bold',
      margin: `${common_space.m} 0 ${common_space.s}`,
      paddingBottom: '.1rem',
      borderBottom: `1px solid ${common_color.gray}`,
      [media_mobile.tab]: {
        fontWeight: 'normal',
        marginTop: common_space.l,
      },
      '& + h3, & + h4': {
        marginTop: common_space.s,
        [media_mobile.tab]: {
          marginTop: common_space.m,
        },
      },
      '& + .list_title': {
        marginTop: '0',
        [media_mobile.tab]: {
          marginTop: common_space.s,
        },
      },
      '& + .gatsby-highlight': {
        marginTop: '0',
        [media_mobile.tab]: {
          marginTop: common_space.s,
        },
      },
    },
    '.gatsby-highlight + h2, h3, h4': {
      marginTop: common_space.s,
      [media_mobile.tab]: {
        marginTop: common_space.m,
      },
    },
    '.the_beginning_of_the_sentence': {
      '& + *': {
        marginTop: '0',
      },
    },
    h2: {
      fontSize: '1.2em',
      lineHeight: '1.7em',
      marginTop: common_space.l,
      paddingBottom: '.2rem',
      borderBottomWidth: '2px',
      [media_mobile.tab]: {
        fontSize: '1.4em',
        lineHeight: '1.8em',
        marginTop: '8rem',
      },
    },
    h3: {
      fontSize: '1.2em',
      lineHeight: '1.6em',
      [media_mobile.tab]: {
        fontSize: '1.3em',
        lineHeight: '1.7em',
      },
    },
    h4: {
      fontSize: '1.1em',
      lineHeight: '1.5em',
      marginRight: 'auto',
      [media_mobile.tab]: {
        fontSize: '1.1em',
        lineHeight: '1.6em',
      },
    },
    '.list_title': {
      textAlign: 'center',
      marginTop: common_space.s,
      [media_mobile.tab]: {
        marginTop: `calc(${common_space.m} - 1rem)`,
      },
    },
    'ul + .list_title, ol + .list_title': {
      marginTop: '-1rem',
    },
    '& > .list_title + ul, & > .list_title + ol': {
      marginTop: '0',
    },
    '& > ul, & > ol': {
      display: 'inline-block',
      width: '100%',
      margin: `${common_space.s} 0 calc(${common_space.s} + 1rem)`,
      padding: '1rem',
      border: `solid 3px ${common_color.gray}`,
      borderRadius: '4px',
      // lineHeight: '2',
      [media_mobile.tab]: {
        margin: `${common_space.m} auto calc(${common_space.m} + 1rem)`,
      },
      '& + h2': {
        marginTop: `calc(${common_space.l} - (${common_space.s} + 1rem))`,
      },
      '& + h3, & + h4': {
        marginTop: `calc(${common_space.m} - (${common_space.s} + 1rem))`,
      },
    },
    ul: {
      paddingLeft: '3.1rem',
      [media_mobile.tab]: {
        paddingLeft: '3.5rem',
      },
      ul: {
        paddingLeft: '2rem',
      },
      li: {
        position: 'relative',
        marginBottom: '.5rem',
        '&:last-of-type': {
          marginBottom: '0',
        },
        '&::before': {
          position: 'absolute',
          top: '.2em',
          left: '-2em',
          width: '1.4em',
          height: '1.4em',
          content: '""',
          display: 'inline-block',
          backgroundImage: svg_ticksign(common_color.gray_light),
          [media_mobile.tab]: {
            top: '.4rem',
          },
        },
      },
    },
    ol: {
      counterReset: 'item',
      paddingLeft: '3.2rem',
      [media_mobile.tab]: {
        paddingLeft: '3.6rem',
      },
      p: {
        display: 'inline',
      },
      ol: {
        paddingLeft: '2.3rem',
      },
      li: {
        position: 'relative',
        marginBottom: '.5rem',
        '&:last-of-type': {
          marginBottom: '0',
        },
        '&::before': {
          position: 'absolute',
          top: '.2rem',
          left: '-1.6rem',
          counterIncrement: 'item',
          content: 'counter(item)',
          fontFamily: 'EnglishGothic',
          fontDisplay: 'swap',
          fontSize: '1rem',
          transform: 'translate(-50%, 0)',
          [media_mobile.tab]: {
            top: '.3rem',
          },
        },
      },
    },
    table: {
      margin: `${common_space.s} auto`,
      lineHeight: '1.2em',
      borderCollapse: 'collapse',
      '& th': {
        borderBottom: `2px solid ${common_color.gray}`,
      },
      '& td, th': {
        padding: '.5rem',
      },
      [media_mobile.tab]: {
        margin: `${common_space.m} auto`,
      },
    },
    hr: {
      margin: `${common_space.l} 0`,
      border: '0',
      height: '1px',
      backgroundImage: `linear-gradient(to left, #eee, ${common_color.gray}, #eee)`,
      textAlign: 'center',
      [media_mobile.tab]: {
        margin: `8rem 0`,
      },
      '&:after': {
        // content: svg_kilroy(),
        content: '""',
        display: 'inline-block',
        position: 'relative',
        top: '-19.5px',
        // margin: '0 -1px',
        background: 'white',
        width: '2.5rem',
        height: '2.5rem',
        backgroundImage: svg_kilroy(),
        // color: common_color.gray,
        // fontSize: '1em',
      },
    },
    em: {
      fontStyle: 'normal',
      // color: 'red',
      fontWeight: 'bold',
    },
    strong: {
      margin: '0 .1rem',
      fontWeight: 'normal',
      fontStyle: 'normal',
      background: effect.underline(common_color.orcher_light),
    },
    'strong > em': {
      fontWeight: 'bold',
    },
    blockquote: {
      position: 'relative',
      margin: `${common_space.s} 0`,
      padding: '1rem',
      border: `3px solid ${common_color.gray}`,
      borderRadius: '4px',
      [media_mobile.tab]: {
        margin: `${common_space.m} 0`,
      },
      '&::before': {
        content: '""',
        display: 'inline-block',
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        width: '3rem',
        height: '3rem',
        borderRadius: '0 0 2rem',
        backgroundColor: common_color.gray,
      },
      '&::after': {
        content: svg_quote_round('%23ffffff'),
        position: 'absolute',
        width: '1.5rem',
        height: '1.5rem',
        top: '.4rem',
        left: '.5rem',
      },
      p: {
        paddingLeft: '3rem',
        whiteSpace: 'pre-wrap',
        '*': {
          whiteSpace: 'normal',
        },
      },
      '& + h2, h3, h4': {
        marginTop: common_space.s,
        [media_mobile.tab]: {
          marginTop: common_space.m,
        },
      },
    },
    '.anchor.before': {
      top: '.25em',
    },
    'h2 > .anchor.before': {
      top: '.4em',
    },
    'h3 > .anchor.before': {
      top: '.35em',
    },
    'h4 > .anchor.before': {
      top: '.3em',
    },
    '.gatsby-resp-image-wrapper': {
      marginTop: 0,
      marginBottom: 0,
      // [media_mobile.tab]: {
      //   marginTop: common_space.s,
      //   marginBottom: common_space.s,
      // },
    },
  }
)

export const iframely_style = css({
  '.iframely-embed': {
    marginTop: common_space.s,
    marginBottom: common_space.s,
    [media_mobile.tab]: {
      marginTop: common_space.m,
      marginBottom: common_space.m,
    },
    '& + .iframely-embed': {
      marginTop: 0,
      [media_mobile.tab]: {
        marginTop: 0,
      },
    },
    '& + blockquote': {
      marginTop: 0,
      [media_mobile.tab]: {
        marginTop: 0,
      },
    },
  },
  '.twitter-tweet': {
    margin: '0 auto',
  },
})

export default main_text_style
