import React, { useEffect } from 'react'
import { motion, useCycle } from 'framer-motion'

import { BlogPostPageQuery } from '../../../../../types/graphql-types'
import {
  common_color,
  media_mobile_value,
} from '../../../../style/common_value'
import {
  table_of_contents_style,
  table_of_contents_caption_wapper_style,
  table_of_contents_caption_container_style,
  svg_laytou_list_style,
} from '../blog_post_css/blog_post_table_of_contents'

import { SvgLayoutList } from '../../../svg_components/LayoutList'
import TableOfContentsMain from './TableOfContentsMain'

const caption_variants = {
  close: {
    color: common_color.text,
    backgroundColor: '#ffffff',
    borderRadius: '4px 4px 4px 4px',
    transition: {
      duration: 0.3,
      delay: 0.3,
      ease: 'easeInOut',
    },
  },
  open: {
    color: '#ffffff',
    backgroundColor: common_color.gray,
    borderRadius: '4px 4px 0 0',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}
const svg_variants = {
  close: {
    fill: common_color.gray,
    transition: {
      fill: { duration: 0.3, delay: 0.3 },
      ease: 'easeInOut',
    },
  },
  open: {
    fill: '#ffffff',
    transition: {
      fill: { duration: 0.3 },
      ease: 'easeInOut',
    },
  },
}

type props_type = {
  html: BlogPostPageQuery['contentfulBlogPost']['main_text']['childMarkdownRemark']['html']
}

export const BlogPostTableOfContents: React.FC<props_type> = ({ html }) => {
  const [toc_state, toc_cycle] = useCycle(false, true)

  useEffect(() => {
    if (matchMedia(`(min-width: ${media_mobile_value.sp})`).matches) {
      toc_cycle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      css={table_of_contents_style}
      initial="open"
      animate={toc_state ? 'open' : 'close'}
    >
      <motion.div
        className="table_of_contents_caption"
        css={table_of_contents_caption_wapper_style}
        onClick={() => toc_cycle()}
        variants={caption_variants}
      >
        <div css={table_of_contents_caption_container_style}>
          <SvgLayoutList css={svg_laytou_list_style} variants={svg_variants} />
          目次
        </div>
      </motion.div>
      <TableOfContentsMain html={html} />
    </motion.div>
  )
}

export default BlogPostTableOfContents
