import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgRefresh: React.FC<props_type> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <title>更新日</title>
      <path d="M13.5 2C7.871 2 3.288 6.436 3.025 12H0l4.537 5.917L9 12H6.025c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5S17.636 20 13.5 20a7.483 7.483 0 01-5.876-2.854l-1.847 2.449A10.46 10.46 0 0013.5 23C19.298 23 24 18.298 24 12.5S19.298 2 13.5 2z" />
    </svg>
  )
}
export default SvgRefresh
