import React from 'react'

import {
  main_text_style,
  iframely_style,
} from './blog_post_css/blog_post_main_css'
import { BlogPostPageQuery } from '../../../../types/graphql-types'
import { adjust_main_text } from '../../../utils'

import { BlogPostIframely } from './BlogPostIframely'
import BlogPostTableOfContents from './BlogPostTableOfContents'

type props_type = {
  blog_post: BlogPostPageQuery['contentfulBlogPost']
}

export const BlogPostMainText: React.FC<props_type> = ({ blog_post }) => {
  const blog_remark = blog_post.main_text.childMarkdownRemark
  // 文章の最初にhタグのトップマージンを消す為の仮要素
  // const adjust_main_text = `<div class="the_beginning_of_the_sentence"></div>${blog_remark.html}`

  return (
    <div className="blog_post_container">
      <BlogPostIframely />
      {blog_post.description?.childMarkdownRemark?.html ? (
        <div
          /* eslint-disable react/no-danger */
          dangerouslySetInnerHTML={{
            __html: blog_post.description.childMarkdownRemark.html,
          }}
          /* eslint-enable react/no-danger */
          css={main_text_style}
        />
      ) : null}
      {!blog_post.table_of_contents_disable ? (
        <BlogPostTableOfContents html={blog_remark.html} />
      ) : null}
      <div
        className="blog_post_main_text"
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={{
          __html: adjust_main_text(blog_remark.html),
        }}
        /* eslint-enable react/no-danger */
        css={[main_text_style, iframely_style]}
      />
    </div>
  )
}

export default BlogPostMainText
