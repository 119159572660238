import React from 'react'
import { motion, MotionProps } from 'framer-motion'
import { SerializedStyles } from '@emotion/react'

type CssMotionProps = MotionProps & { css: SerializedStyles }

export const SvgLayoutList: React.FC<CssMotionProps> = (props) => {
  return (
    <motion.svg
      viewBox="0 0 64 64"
      aria-labelledby="title"
      aria-describedby="desc"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <title>目次</title>
      <path data-name="layer2" d="M0 0h16v16H0z" />
      <path data-name="layer1" d="M24 0h40v16H24z" />
      <path data-name="layer2" d="M0 48h16v16H0z" />
      <path data-name="layer1" d="M24 48h40v16H24z" />
      <path data-name="layer2" d="M0 23.988h16v16H0z" />
      <path data-name="layer1" d="M24 23.988h40v16H24z" />
    </motion.svg>
  )
}
export default SvgLayoutList
