import { css } from '@emotion/react'

import { media_mobile } from './common_value'
import { svg_wavy_mouth_emoji } from './svg_in_css'

export const emoji_style = css({
  '.wavy_mouth_emoji': {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    bottom: '-0.25em',
    display: 'inline-block',
    width: '1.15em',
    height: '1.15em',
    backgroundImage: svg_wavy_mouth_emoji(),
    backgroundRepeat: 'no-repeat',
    margin: '0 .15em',
    [media_mobile.tab]: {
      bottom: '-0.25em',
    },
  },
})

export default emoji_style
