import React from 'react'
import Helmet from 'react-helmet'

export const BlogPostHelmet: React.FC = () => {
  return (
    <Helmet>
      <link
        rel="preload"
        href="/web_fonts/FiraCode/FiraCode-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/web_fonts/FiraCode/FiraCode-VF.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/web_fonts/EnglishGothic/EnglishGothic.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

export default BlogPostHelmet
