import React from 'react'

import { BlogPostPageQuery } from '../../../types/graphql-types'

import { PostMetadata, JsonLdMetadata } from './MetadataCore'

type props_type = {
  blog_post: BlogPostPageQuery['contentfulBlogPost']
  meta_data: BlogPostPageQuery['contentfulSiteMetaData']
}

export const BlogPostMetadata: React.FC<props_type> = ({
  blog_post,
  meta_data,
}) => {
  const tag_names_list = blog_post.tag
    ? blog_post.tag.map((tag) => tag.name)
    : []
  const common_variavle = {
    page_type: 'article' as const,
    // ポスト報関連
    site_title: `${meta_data.site_title}`,
    page_title: `${blog_post.title} | ${meta_data.site_title}`,
    page_description:
      blog_post.description?.childMarkdownRemark?.excerpt ||
      blog_post.main_text?.childMarkdownRemark?.excerpt ||
      meta_data.site_description,
    ogp_images:
      blog_post.ogp_images ||
      blog_post.category?.ogp_images ||
      meta_data.ogp_images,
    logo_image: meta_data.logo_image.file.url,
    tag_names: [
      blog_post.category.master_category.name,
      blog_post.category.name,
      ...tag_names_list,
    ],
    // meta
    author: blog_post.author ?? meta_data.author_data,
    page_fullpath: `${meta_data.site_url}/${blog_post.slug}`,

    // TODO: 構造を除去したためにパンくずリストが設定できなくなった、削除を検討
    // bread_crumb_list: [
    //   { name: 'HOME', item: `${meta_data.site_url}` },
    //   {
    //     name: blog_post.category.master_category.name,
    //     item: `${blog_post.category.master_category.slug}`,
    //   },
    //   {
    //     name: blog_post.category.name,
    //     item: `${blog_post.category.slug}`,
    //   },
    //   {
    //     name: blog_post.title,
    //     item: `${blog_post.slug}`,
    //   },
    // ],
    // date
    published_date: blog_post.published_date,
    modified_date: `${blog_post.modified_date ?? blog_post.published_date}`,
    category_name: blog_post.category.name,
  }
  return (
    <>
      <PostMetadata metadata={common_variavle} />
      <JsonLdMetadata metadata={common_variavle} />
      {/* TODO: 構造を除去したためにパンくずリストが設定できなくなった、削除を検討
       <BreadJsonLd bread_crumb_list={common_variavle.bread_crumb_list} /> */}
    </>
  )
}

export default BlogPostMetadata
