import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgUpload: React.FC<props_type> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <title>公開日</title>
      <path d="M17 22v2H7v-2h10zm0-4H7v2h10v-2zM7 11v5h10v-5h6L12 0 1 11h6z" />
    </svg>
  )
}
export default SvgUpload
