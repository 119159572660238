import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { limit_number_of_characters } from '../../../../utils'
import { too_long_title } from '../../../../style/common_value'
import {
  BlogPostPageQuery,
  BlogPostNextPrevQuery,
} from '../../../../../types/graphql-types'

import {
  next_prev_container_style,
  next_prev_link_common_style,
  next_prev_link_inner_style,
  image_wrapper_style,
  image_container_style,
  blank_style,
  post_title_style,
  button_title_style,
  next_add_css,
  prev_add_css,
  add_css_type,
} from './next_prev_css'

type blog_post_edge_type =
  BlogPostNextPrevQuery['allContentfulBlogPost']['edges'][0]['node']
type next_prev_type = {
  edge: blog_post_edge_type
  add_css?: add_css_type
  title: string
}

const NextPrevLinkContent: React.FC<next_prev_type> = ({
  edge,
  add_css,
  title,
}) => {
  return (
    <div css={[next_prev_link_common_style, add_css.master]}>
      <Link to={`/${edge.slug}`}>
        <div css={next_prev_link_inner_style}>
          <div css={[image_wrapper_style, add_css.image_wrapper]}>
            <div css={[image_container_style, add_css.image_container]}>
              <GatsbyImage
                image={
                  edge.thumbnail?.gatsbyImageData ??
                  edge.category.thumbnail.gatsbyImageData
                }
                alt={edge.thumbnail?.title ?? edge.category.thumbnail.title}
              />
            </div>
            <div css={[post_title_style, add_css.post_title]}>
              <div className="post_title_inner">
                {limit_number_of_characters(edge.title, too_long_title)}
              </div>
            </div>
          </div>
        </div>
        <div css={[button_title_style, add_css.button]}>{title}</div>
      </Link>
    </div>
  )
}

// main component

type props_type = {
  blog_post: BlogPostPageQuery['contentfulBlogPost']
}

export const BlogPostNextPrev: React.FC<props_type> = ({ blog_post }) => {
  const query: BlogPostNextPrevQuery = useStaticQuery(graphql`
    query BlogPostNextPrev {
      allContentfulBlogPost(
        limit: 1000
        sort: { fields: published_date, order: DESC }
      ) {
        edges {
          node {
            title
            slug
            published_date
            thumbnail {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            category {
              name
              slug
              thumbnail {
                title
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              master_category {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)
  const current_slug = blog_post.slug

  const { edges } = query.allContentfulBlogPost

  const edges_length = edges.length

  const current_index = edges.findIndex(
    (item) => item.node.slug === current_slug
  )

  const next_edge = current_index !== 0 && edges[current_index - 1].node
  const prev_edge =
    current_index !== edges_length - 1 && edges[current_index + 1].node

  return (
    <div className="next_prev_container" css={next_prev_container_style}>
      {next_edge ? (
        <NextPrevLinkContent
          edge={next_edge}
          add_css={next_add_css}
          title="次の記事"
        />
      ) : (
        <div css={blank_style} />
      )}
      <br />
      {prev_edge ? (
        <NextPrevLinkContent
          edge={prev_edge}
          add_css={prev_add_css}
          title="前の記事"
        />
      ) : (
        <div css={blank_style} />
      )}
    </div>
  )
}

export default BlogPostNextPrev
