import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgCategory: React.FC<props_type> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <title>カテゴリー</title>
      <path d="M11 5h13v17H0V2h8l3 3zM1 3v18h22V6H10.586l-3-3H1z" />
    </svg>
  )
}
export default SvgCategory
